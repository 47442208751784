import * as React from 'react';


import { MainLayout, Seo } from '../components';



const ContactPage = () => {

  const pageTitle = 'Contact';
  const onMapLoad = (event) => {
    event.target.width = 0.9 * Math.min(document.body.clientWidth, 1280);
  };

  return (
    <>
      <Seo title={pageTitle} />
      <MainLayout title={pageTitle}>
        <div>
          <h1>Contact</h1>
          <div style={{ marginBottom: '1em' }}>
            <div>Romanian Orthodox Church - "St. Apostle Andrew"</div>
            <div>4030 Dixie Rd. Mississauga, ON</div>
            <div>L4W 1M4, Canada</div>
            <div>Telefon: 905-282-9481</div>
          </div>
          <iframe 
            title="Hartă Google"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.209946237517!2d-79.60579304854329!3d43.62298907901979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b387f93c33ce5%3A0xa6b2725971d97cc5!2s4030%20Dixie%20Rd%2C%20Mississauga%2C%20ON%20L4W%201M4!5e0!3m2!1sen!2sca!4v1647730968612!5m2!1sen!2sca" 
            width="600" 
            height="450" 
            style={{ border: 0 }} 
            allowFullScreen={true}
            loading="lazy"
            onLoad={onMapLoad}
            >
          </iframe>
        </div>
      </MainLayout>
    </>
  );
};

export default ContactPage;


